<template>
  <section class="pb-10">
    <v-row
      class="pt-5"
      justify="center"
      align="center"
    >
      <v-col
        cols="11"
        md="8"
        lg="8"
        xl="5"
      >
        <kn-tabs
          :items="tabs"
          :tos="true"
          @tabValue="setTabValue"
        />
      </v-col>
    </v-row>
    <v-row
      class="pt-0"
      justify="center"
      align="center"
    >
      <v-col :cols="cols" >
        <router-view></router-view>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import KnTabs from '../../modules/shared/components/KnTabs.vue'
// import KnSale from './KnSale.vue'
export default {
  components: {KnTabs },
  data() {
    return {
      valueDeterminate: 33,
      tabs: [
        { name: 'Venta', value: 33 },
        { name: 'Ordenes', value: 66 },
        { name: 'Inventario', value: 100 }
      ]
    }
  },
  computed: {
    ...mapState(['isPOS']),
    cols() {
      return this.isPOS ? "11" : "10"
    }
  },
  created() {
    this.setIsLogin(false)
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    setTabValue(val) {
      this.valueDeterminate = val
    },
  }
}
</script>

<style>
.pos-background {
  background-color: gray;
}
</style>